<template>
  <div class="detail-drawer">
    <a-drawer
      title="应用详情"
      width="620"
      :closable="true"
      :visible="visible"
      @close="onClose"
    >
      <template v-if="detail">
        <div class="app">
          <div class="icon">
            <img :src="'https://pinews.s3.filebase.com' + detail.icon" />
          </div>
          <div class="app-name">{{ detail.app_name }}</div>
        </div>
        <a-descriptions title="基本信息" :column="2">
          <a-descriptions-item label="是否可用" :span="1">
            <a-tag v-if="detail.is_available" color="#87d068">正常</a-tag>
            <a-tag v-else color="#f50">不可用</a-tag>
          </a-descriptions-item>
          <a-descriptions-item label="应用分类" :span="1">
            {{ detail.category.name }}
          </a-descriptions-item>
          <a-descriptions-item label="后台添加" :span="1">
            <a-tag v-if="detail.is_add_directly" color="#87d068">是</a-tag>
            <a-tag v-else color="#FF7D00">否</a-tag>
          </a-descriptions-item>
          <!-- <a-descriptions-item label="所有权验证" :span="1">
            <a-tag v-if="detail.ownership_verified" color="#87d068">
              已验证
            </a-tag>
            <a-tag v-else color="#f50">待验证</a-tag>
          </a-descriptions-item> -->
          <a-descriptions-item label="应用链接" :span="2">
            {{ detail.url }}
          </a-descriptions-item>
          <a-descriptions-item label="应用简介" :span="2">
            {{ detail.summary }}
          </a-descriptions-item>
          <a-descriptions-item label="提交时间" :span="2">
            {{ $dayjs(detail.create_at).format('YYYY-MM-DD HH:mm:ss') }}
          </a-descriptions-item>
        </a-descriptions>
        <ul id="gallery">
          <li v-for="(img, index) in detail.previews" :key="index">
            <img class="img" :src="'https://pinews.s3.filebase.com' + img" />
          </li>
        </ul>
        <a-descriptions title="实时数据" :column="2">
          <a-descriptions-item label="评分" :span="1">
            {{ detail.rate }}
          </a-descriptions-item>
          <a-descriptions-item label="评论数量" :span="1">
            {{ detail.rate_count }}
          </a-descriptions-item>
          <a-descriptions-item label="浏览量" :span="1">
            {{ detail.view_count }}
          </a-descriptions-item>
          <a-descriptions-item label="点击量（跳转次数）" :span="1">
            {{ detail.click_count }}
          </a-descriptions-item>
          <a-descriptions-item label="分享次数" :span="1">
            {{ detail.share_count }}
          </a-descriptions-item>
        </a-descriptions>
      </template>
    </a-drawer>
  </div>
</template>

<script>
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';

export default {
  name: 'DetailDrawer',
  data() {
    return {
      visible: false,
      detail: null,
    };
  },
  computed: {
  },
  methods: {
    open(detail) {
      this.detail = detail;
      this.visible = true;
      this.$nextTick(() => {
        new Viewer(document.getElementById('gallery'), {
          scalable: false,
        });
      });
    },
    onClose() {
      this.detail = null;
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.app {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .icon {
    margin-right: 20px;
    img {
      width: 100px;
      height: 100px;
      border-radius: 20%;
    }
  }
  .app-name {
    font-size: 20px;
    font-weight: 500;
  }
}
#gallery {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  li {
    display: inline-block;
  }
  .img {
    width: 100px;
    border-radius: 10px;
    margin-right: 10px;
    box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
      0 1px 3px 1px rgb(60 64 67 / 15%);
  }
}
</style>
